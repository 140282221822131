import '@babel/polyfill';
import moduleNamesMap from '@int_acf_core/cartridge/config/moduleNamesMap';

// eslint-disable-next-line
__webpack_public_path__ = window.publicPath;

window.modulePresentOnThePage = window.modulePresentOnThePage || [];

const moduleBrandStyleLoaders = {
    bubbablade_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/bubbablade_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/bubbablade_us/modules/${scssFileName}.scss`);
    },
    caldwell_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/caldwell_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/caldwell_us/modules/${scssFileName}.scss`);
    },
    frankford_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/frankford_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/frankford_us/modules/${scssFileName}.scss`);
    },
    wheeler_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/wheeler_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/wheeler_us/modules/${scssFileName}.scss`);
    },
    bog_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/bog_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/bog_us/modules/${scssFileName}.scss`);
    },
    smithwesson_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/smithwesson_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/smithwesson_us/modules/${scssFileName}.scss`);
    },
    thompsoncenter_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/thompsoncenter_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/thompsoncenter_us/modules/${scssFileName}.scss`);
    },
    hooyman_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/hooyman_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/hooyman_us/modules/${scssFileName}.scss`);
    },
    lockdown_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/lockdown_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/lockdown_us/modules/${scssFileName}.scss`);
    },
    meat_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/meat_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/meat_us/modules/${scssFileName}.scss`);
    },
    meat_ca(scssFileName) {
        return import(/* webpackChunkName: 'module_system/meat_ca/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/meat_ca/modules/${scssFileName}.scss`);
    },
    crimsontrace_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/crimsontrace_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/crimsontrace_us/modules/${scssFileName}.scss`);
    },
    ust_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/ust_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/ust_us/modules/${scssFileName}.scss`);
    },
    tipton_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/tipton_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/tipton_us/modules/${scssFileName}.scss`);
    },
    schrade_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/schrade_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/schrade_us/modules/${scssFileName}.scss`);
    },
    oldtimer_us(scssFileName) {
        return import(/* webpackChunkName: 'module_system/oldtimer_us/[request]' */ `@int_acf_core/cartridge/sass/modules_system/brands/oldtimer_us/modules/${scssFileName}.scss`);
    }
};

if (window.modulesSystem.isDevelopmentModeOn) {
    window.modulesSystem.modulePresentOnThePage.forEach(moduleId => {
        if (moduleNamesMap[moduleId]) {
            const scssFileName = moduleNamesMap[moduleId];

            // importing module styles
            moduleBrandStyleLoaders[window.currentSiteID](scssFileName).catch(() => {
                console.error(`Module styles file not found: @int_acf_core/cartridge/sass/modules_system/brands/${window.currentSiteID}/modules/${scssFileName}.scss`);
            });
        }
    });
}
